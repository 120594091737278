import '../styles/Cart.css'

function Cart() {
    const plante1 = "monstera"
    const plante2 = "lierre"
    const plante3 = "bouquet"
    const prix1 = 8
    const prix2 = 10
    const prix3 = 15
    return (
    <div className='lmj-cart'>
        <h2>Panier</h2>
        <ul>
            <li>{plante1} {prix1} €</li>
            <li>{plante2} {prix2} €</li>
            <li>{plante3} {prix3} €</li>
        </ul>
        Total : {prix1+prix2+prix3}
    </div>
        )
}

export default Cart